import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=73b3db9e&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73b3db9e",
  null
  
)

export default component.exports